import React, { useEffect, useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { paymentApi } from '../../../../actions/catalogs/accounters';
import { TableBody, TextField, Box, MenuItem, Select, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalContext } from '../../../modal/ModalContext';
import { ValidatePassword } from '../../crm/sales/PreSale/ValidatePassword';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export const ValidatePayment = () => {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const datos = useSelector(state => state.paymentApiData.data.items);
    const validators = useSelector(state => state.paymentApiData.data.data);
    const totalPages = useSelector(state => state.paymentApiData?.data?.totalPages || 1); // Valor por defecto de 1 si no existe
    const dataList = datos ? Object.values(datos) : [];
    const [itemsPerPage, setItemsPerPage] = useState(20); // Valor por defecto de 20
    const { handleModal } = useContext(ModalContext);
    const pagesToShow = 4;
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tipeDate, setTipeDate] = useState(0);
    const [rawStartDate, setRawStartDate] = useState('');
    const [rawEndDate, setRawEndDate] = useState('');
    const [validFilter, setValidFilter] = useState('');
    const [paymentMethodFilter, setPaymentMethodFilter] = useState('');
    const startPage = Math.max(1, pageNumber - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    if (endPage < startPage) {
        endPage = startPage;
    }
    const pagesToRender = Math.max(0, endPage - startPage + 1);
    let result = [];

    useEffect(() => {
        if (!(user.descriptionRole === "Accounter" && user.isBoss === true)) {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [user, Link]);

    const handleSearchChange = (event) => {
        setSearch2(event.target.value);
    };

    const handleSearchClick = () => {
        setSearchTerm(search2);

    };

    // var uniqs = datos && Array.isArray(datos)
    //     ? [...new Set(datos.map(item => item.attended))]
    //     : []; 

    // console.log(uniqs);

    useEffect(() => {
        if (startDate === "" && endDate === "" && tipeDate === "" && rawStartDate === "" && rawEndDate === "" && search2 === "") {
            dispatch(paymentApi(pageNumber, searchTerm, itemsPerPage, rawStartDate, rawEndDate, tipeDate));
        }
    }, [startDate, endDate, tipeDate, rawStartDate, rawEndDate, search2, dispatch, pageNumber, searchTerm, itemsPerPage]);

    const handleResetClick = () => {
        setStartDate("");
        setRawEndDate("");
        setSearchTerm("");
        setEndDate("");
        setTipeDate("");
        setRawStartDate("");
        setSearch2("");
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages - pagesToShow + 1) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handleItemsPerPageChange = (event) => {
        const value = event.target.value;
        setItemsPerPage(value);
        dispatch(paymentApi(pageNumber, searchTerm, value, rawStartDate, rawEndDate, tipeDate));
    };


    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handleDateChange = (event) => {
        const originalDate = event.target.value;

        const [year, month, day] = originalDate.split('-');
        const formattedDate = `${month}/${day}/${year}`;

        if (event.target.name === 'startDateName') {
            setStartDate(originalDate)
            setRawStartDate(formattedDate);
        } else {
            setEndDate(originalDate);
            setRawEndDate(formattedDate);
        }
    };


    const handleTipeDateChange = (event) => {
        setTipeDate(event.target.value);
    }

    useEffect(() => {
        dispatch(paymentApi(pageNumber, searchTerm, itemsPerPage, rawStartDate, rawEndDate, tipeDate));
    }, [dispatch, pageNumber, itemsPerPage, searchTerm]);

    const searcher = (e) => {
        setSearch(e.target.value);
    };

    if (!search) {
        result = dataList;
    } else {
        result = dataList.filter((dato) =>
            dato.clientName.toLowerCase().includes(search.toLowerCase()) ||
            dato.clientEmail.toLowerCase().includes(search.toLowerCase()) ||
            dato.sellerName.toLowerCase().includes(search.toLowerCase()) ||
            dato.sellerEmail.toLowerCase().includes(search.toLowerCase()) ||
            dato.statusPayment.toLowerCase().includes(search.toLowerCase())
        );
    }

    if (statusFilter) {
        result = result.filter((dato) => dato.statusPayment === statusFilter);
    }

    useEffect(() => {
        if (startDate && endDate && tipeDate != '') {
            dispatch(paymentApi(pageNumber, searchTerm, itemsPerPage, rawStartDate, rawEndDate, tipeDate));
        }
    }, [dispatch, pageNumber, itemsPerPage, searchTerm, tipeDate]);

    if (validFilter) {
        result = result.filter((dato) => String(dato.attended) === validFilter);
    }

    if (paymentMethodFilter) {
        result = result.filter((dato) => dato.paymentWay === paymentMethodFilter);
    }

    result = result.sort((a, b) => {
        if (a.statusPayment === "StandBy" && b.statusPayment !== "StandBy") return -1;
        if (a.statusPayment !== "StandBy" && b.statusPayment === "StandBy") return 1;
        const dateA = new Date(a.paymentRequestDate);
        const dateB = new Date(b.paymentRequestDate);
        return dateB - dateA;
    });

    const conluirTramite = (saleId) => {
        handleModal(<ValidatePassword saleId={saleId} />);
    };

    return (
        <div style={{ margin: '10px', overflow: 'hidden' }}>
            {
                !(user.descriptionRole === "Accounter" && user.isBoss === true) ? (
                    <div>Usuario no autorizado para esta vista</div>
                ) : (
                    <div>
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <Grid container spacing={2} alignItems="center">
                                {/* Input para buscar en la página actual */}
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title="Solo buscará usuarios en la página actual.">
                                        <TextField
                                            onChange={searcher}
                                            value={search}
                                            variant="outlined"
                                            placeholder="Buscar usuario en la página actual."
                                            fullWidth
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Desde"
                                        type="date"
                                        value={startDate}
                                        name='startDateName'
                                        onChange={handleDateChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Hasta"
                                        type="date"
                                        value={endDate}
                                        name='endDateName'
                                        onChange={handleDateChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={12} sm={2}>
                                    <InputLabel id="demo-simple-select-filled-label">Tipo busqueda por fecha</InputLabel>
                                    <Select
                                        value={tipeDate}
                                        onChange={handleTipeDateChange}
                                        variant="outlined"
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="1">Fecha Pago</MenuItem>
                                    </Select>
                                </Grid>

                                {/* Filtros adicionales */}
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="demo-simple-select-filled-label">Estatus de pago</InputLabel>
                                    <Select
                                        value={search2}
                                        onChange={handleSearchChange}

                                        variant="outlined"
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value=" ">Todos</MenuItem>
                                        <MenuItem value="StandBy">StandBy</MenuItem>
                                        <MenuItem value="Accepted">Accepted</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Filtro por usuarios */}
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="demo-simple-select-filled-label">Quien validó el pago</InputLabel>
                                    <Select
                                        value={search2}
                                        onChange={handleSearchChange}
                                        variant="outlined"
                                        displayEmpty
                                        fullWidth
                                    >
                                        {validators?.map((validator) => (
                                            <MenuItem value={validator.email}>
                                                {validator.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="demo-simple-select-filled-label">Metodo de pago</InputLabel>
                                    <Select
                                        value={search2}
                                        onChange={handleSearchChange}
                                        variant="outlined"
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value=" ">Todos</MenuItem>
                                        <MenuItem value="BanxicoTransfer">BanxicoTransfer</MenuItem>
                                        <MenuItem value="VelPay">VelPay</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Input para buscar en todos los registros */}
                                <Grid item xs={12} sm={2}>
                                    <InputLabel id="demo-simple-select-filled-label">Buscar usuario general</InputLabel>
                                    <Tooltip title="Buscar usuario en todos los registros existentes">
                                        <TextField
                                            onChange={handleSearchChange}
                                            value={search2}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12} sm={1} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid item style={{ marginBottom: '3px' }}>
                                        <Button
                                            onClick={handleSearchClick}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            Buscar
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            onClick={handleResetClick}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            Limpiar filtros
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <TableContainer style={{ borderRadius: '10px', maxHeight: '690px' }}>
                            <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>ID</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Cliente</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Vendedor</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de solicitud de pago</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de pago</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Estatus del pago</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Validó pago</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Método de pago</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Datos del movimiento</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Descripción de venta</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Total</TableCell>
                                        <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Validar pago</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result.map((report, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.idPayment}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Nombre:{report.clientName}<br />RFC: {report.rfc}<br />Email {report.clientEmail}</TableCell>

                                            {/* <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.clientName}
                                            </TableCell> */}
                                            {/* <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.clientEmail}
                                            </TableCell> */}
                                            {/* <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.sellerName}
                                            </TableCell> */}
                                            <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Nombre:{report.sellerName}<br />Email {report.sellerEmail}</TableCell>

                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.paymentRequestDate}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.paymentDate}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.statusPayment}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {String(report.attendedName)}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {report.paymentWay}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Cuenta receptora:{report.receptorAccount}<br />Banco receptor: {report.receptorBank}<br />CLABE receptora: {report.receptorClabe}<br />Referencia de pago:{report.paymentReference}<br />Rastreo: {report.paymentTransaction}</TableCell>
                                            <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>
                                                {report.saleProducts.map((product, index) => (
                                                    <div key={index}>
                                                        Descripción: {product.description} <br />
                                                        Total: {product.total} <br />
                                                        Precio unitario: {product.unitPrice} <br />
                                                        <hr />
                                                    </div>
                                                ))}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                {String(report.paymentTotal)}
                                            </TableCell>
                                            {
                                                user.isBoss == true ?
                                                    (
                                                        <TableCell align="center">

                                                            {
                                                                report.statusPayment === "StandBy" ?
                                                                    (
                                                                        <Button variant='contained' color='primary' style={{ marginTop: '2px' }}
                                                                            onClick={() =>
                                                                                conluirTramite(report.idPayment)
                                                                            }
                                                                        >
                                                                            Validar pago
                                                                        </Button>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className="alert alert-success" role="alert">
                                                                            ¡Pago verificado!
                                                                        </div>
                                                                    )
                                                            }
                                                        </TableCell>
                                                    ) :
                                                    (
                                                        <TableCell align="center">

                                                            <div className="alert alert-success" role="alert">
                                                                ¡Usuario no autorizado!
                                                            </div>

                                                        </TableCell>
                                                    )
                                            }

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* Paginación */}
                        <Grid container justifyContent='space-between'>
                            <Grid >
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-controlled-open-select-label">Mostrar</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid style={{ marginTop: '20px' }} >
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={() => setPageNumber(1)}>Inicio</a>
                                        </li>
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
                                        </li>
                                        {[...Array(pagesToRender)].map((_, i) => (
                                            <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                                                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
                                            </li>
                                        ))}
                                        <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handleNextPage}>Siguiente</a>
                                        </li>
                                        <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={() => setPageNumber(totalPages)}>Fin</a>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </div>
    );
};
