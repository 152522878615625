import { LinearProgress, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalContext } from '../../../../../modal/ModalContext';
import { downloadSatStartLoading, sesionSat } from '../../../../../../actions/accounting/periods';
import { useForm } from '../../../../../../hooks/useForm';

export const CaptchaForm2 = ({ msg, successmsg }) => {

    const { captcha } = useSelector(state => state.downloadXMLRequest);
    const { handleModal } = useContext(ModalContext);
    const { handleInputChange, formValues } = useForm({
        imgcaptcha: ''
    });

    const dispatch = useDispatch();
    const requestType = 1
    const canceledSubmit = (e) => {
        e.preventDefault();
        if (requestType == 1) {
            dispatch(sesionSat(handleModal.bind()))
        } else {
            handleModal()
        }

    }
    return (
        <>
            <div>
                <div className="modal-sm">
                    <div className="col-md-12 text-center">
                        <Typography>
                            Da click en aceptar para terminar el proceso.
                        </Typography>
                        <button className="btn btn-success ml-2" onClick={canceledSubmit}>Aceptar</button>
                    </div>
                </div>
            </div>
        </>
    );
};